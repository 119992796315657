import React, { useEffect , useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { pathListner } from '../actions/pathActions';
import { headers } from '../config/ApiHeaders';
import axios from 'axios';
import {listLeads} from '../actions/leadsActions';

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

import { DateRange } from 'react-date-range';

import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

import moment from 'moment';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler,
    ArcElement,
  } from 'chart.js';
  import { Bar, Pie, Line } from 'react-chartjs-2';


ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler,
    ArcElement,
);


export default function Dashboard() {

    const dispatch = useDispatch();

    function numberFormater(x) {

        let nbr = 0
        if(x){
            nbr = x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
        }

        return nbr;
    }

    const [date, setDate] = useState([
        {
          startDate: moment().startOf('month').toDate(),
          endDate: new Date(),
          key: 'selection'
        }
      ]);

    const [dateRange, setDateRange] = useState([moment().startOf('month').toDate(), new Date()]);
    const [startDate, endDate] = dateRange;

    const [dateLoadingData, setDateLoadingData] = useState(false);

    let ReportBaseUrl = "https://data.trocadero-restaurant.com/reporting/";


    /************* Leads count ****************/

    const leadsList = useSelector(state => state.leadsList);
    const { leads } = leadsList;
    
    const [leadsBySourceData, setLeadsBySourceData] = useState();

    const [totalLeadsBySourceData, setTotalLeadsBySourceData] = useState(0);

    const leadsBySourceOptions = {
        responsive: true,
        maintainAspectRatio : false,
        plugins: {
            legend: {
                position: 'top',
                display: false,
                color: 'white',
            },
            title: {
                display: false,
            },
        },
    };


    function getLeadsBySource() {

            // let leadsFacebook = leads.filter(item => item.source == "facebook");
            // let leadsInstagram = leads.filter(item => item.source == "instagram");
            // let leadsGoogle = leads.filter(item => item.source == "google");
            // let leadsDirect= leads.filter(item => item.source == "direct");

            let leadsFacebook = 0;
            let leadsInstagram = 0;
            let leadsGoogle = 0;
            let leadsDirect = 0;


            // if(trafficSourceDetailsData && typeof trafficSourceData === 'object'){
            //     leadsGoogle = trafficSourceData["Organic Search"][2];
            // } 

            if (trafficSourceDetailsData && typeof trafficSourceDetailsData === 'object'){

                let googleLeadsArray = Object.keys(trafficSourceDetailsData).filter((key) => key == 'google' );

                googleLeadsArray.map(function(item){
                    leadsGoogle += Number(trafficSourceDetailsData[item][2]);
                })

                let facebookLeadsArray = Object.keys(trafficSourceDetailsData).filter((key) => key.includes('facebook'));

                facebookLeadsArray.map(function(item){
                    leadsFacebook += Number(trafficSourceDetailsData[item][2]);
                })

                let instagramLeadsArray = Object.keys(trafficSourceDetailsData).filter((key) => key.includes('instagram'));

                instagramLeadsArray.map(function(item){
                    leadsInstagram += Number(trafficSourceDetailsData[item][2]);
                })

                let directLeadsArray = Object.keys(trafficSourceDetailsData).filter((key) => key.includes('direct'));

                directLeadsArray.map(function(item){
                    leadsDirect += Number(trafficSourceDetailsData[item][2]);
                })
            }
            
            let responce = [
                {
                    "label": "Google",
                    "value" : leadsGoogle
                },
                {
                    "label": "Facebook",
                    "value": leadsFacebook
                },
                {
                    "label": "Instagram",
                    "value": leadsInstagram
                },
                {
                    "label": "Direct",
                    "value": leadsDirect
                }
            ];

            let labels = [];
            let data = [];

            responce.forEach(element => {
                labels.push(element.label+"("+element.value+")");
                data.push(element.value);
            });
            
            let chartdata = {
                labels,
                datasets: [
                {
                    label: 'Active Users',
                    data: data,
                    borderColor: [
                        'rgba(255, 206, 86, 1)',
                        'rgba(22, 166, 251, 1)',
                        'rgba(253, 44, 123, 1)',
                        'rgba(74, 221, 166, 1)',
                        'rgba(117, 22, 251, 1)',
                    ],
                    backgroundColor: [
                        'rgba(255, 206, 86, 1)',
                        'rgba(22, 166, 251, 1)',
                        'rgba(253, 44, 123, 1)',
                        'rgba(74, 221, 166, 1)',
                        'rgba(117, 22, 251, 1)',
                    ],
                }
                ],
            };

            setLeadsBySourceData(chartdata);

            setTotalLeadsBySourceData(leadsGoogle + leadsFacebook + leadsInstagram + leadsDirect);
        
    }

    /************* End Leads count ****************/

    /************* Active User By Country ****************/
    const [activeUserByCountryData, setActiveUserByCountryData] = useState();
    const [activeUserByCountryDataIsLoading, setActiveUserByCountryDataIsLoading] = useState(true);

    const activeUserByCountryOptions = {
        indexAxis: 'y',
        elements: {
            bar: {
            borderWidth: 2,
            },
        },
        responsive: true,
        maintainAspectRatio : false,
        plugins: {
            legend: {
                display: false,
                color: 'white',
            }
        },
    };
    
    function getActiveUserByCountryData(startDate, endDate) {

        axios.get(ReportBaseUrl+"data-google-analytics/index.php?report=ActiveUsers_by_country&start_date="+startDate+"&end_date="+endDate)
        .then((res) =>{
            if(res.status === 200){

                let responce = res.data;

                if(Array.isArray(responce) && responce.length != 0){
                    let labels = [];
                    let data = [];

                    responce.forEach(element => {
                        labels.push(element.label +" ("+ element.value +")");
                        data.push(element.value);
                    });
                    
                    let chartdata = {
                        labels,
                        datasets: [
                        {
                            label: 'Active Users',
                            data: data,
                            borderColor: 'rgb(74, 221, 166)',
                            backgroundColor: 'rgb(74, 221, 166)',
                        }
                        ],
                    };

                    setActiveUserByCountryData( chartdata );
                    setActiveUserByCountryDataIsLoading(false);
                    
                }
                        
            }else{
                console.log('error');
            }
        });
        
    }

    /*************** End Active User By Country **************/

    /************* Active User By Device ****************/
    const [activeUserByDeviceData, setactiveUserByDeviceData] = useState();
    const [activeUserByDeviceDataIsLoading, setActiveUserByDeviceDataIsLoading] = useState(true);


    const activeUserByDeviceOptions = {
        responsive: true,
        maintainAspectRatio : false,
        plugins: {
            legend: {
                position: 'top',
                display: false,
                color: 'white',
            },
            title: {
                display: false,
            },
        },
    };

    function getactiveUserByDeviceData(startDate, endDate) {

        axios.get(ReportBaseUrl+"data-google-analytics/index.php?report=ActiveUsers_by_deviceCategory&start_date="+startDate+"&end_date="+endDate)
        .then((res) =>{
            if(res.status === 200){

                let responce = res.data;
                if(Array.isArray(responce) && responce.length != 0){

                    let labels = [];
                    let data = [];

                    responce.forEach(element => {
                        labels.push(element.label+"("+element.value+")");
                        data.push(element.value);
                    });
                    
                    let chartdata = {
                        labels,
                        datasets: [
                        {
                            label: 'Active users',
                            data: data,
                            borderColor: [
                                'rgba(255, 99, 132, 1)',
                                'rgba(74, 221, 166, 1)',
                                'rgba(255, 206, 86, 1)',
                            ],
                            backgroundColor: [
                                'rgba(255, 99, 132, 1)',
                                'rgba(74, 221, 166, 1)',
                                'rgba(255, 206, 86, 1)',
                            ],
                        }
                        ],
                    };

                    setactiveUserByDeviceData( chartdata );
                    setActiveUserByDeviceDataIsLoading(false);
                }
                        
            }else{
                console.log('error');
            }
        });
        
    }

    /*************** End Active User By Device **************/

    /************* Bounce Rate ****************/
    /*
    const [bounceRateData, setBounceRateData] = useState();

    
    function getBounceRateData(startDate, endDate) {

        axios.get(ReportBaseUrl+"data-google-analytics/index.php?report=BounceRate&start_date="+startDate+"&end_date="+endDate)
        .then((res) =>{
            if(res.status === 200){

                let responce = res.data;
                if(Array.isArray(responce) && responce.length != 0){
                    setBounceRateData( Number(responce[0].value).toFixed(2) );
                }
                        
            }else{
                console.log('error');
            }
        });
        
    }
    */
    /*************** End Bounce Rate **************/

    /************* Conversions ****************/
    /*
    const [conversionsData, setConversionsData] = useState();

    function getConversionsData(startDate, endDate) {

        axios.get(ReportBaseUrl+"data-google-analytics/index.php?report=Conversions&start_date="+startDate+"&end_date="+endDate)
        .then((res) =>{
            if(res.status === 200){

                let responce = res.data;
                if(Array.isArray(responce) && responce.length != 0){
                    setConversionsData( responce[0].value );
                }
                        
            }else{
                console.log('error');
            }
        });
        
    }
    */

    /*************** End Conversions **************/

    /************* Bounce Rate And ActiveUsers ****************/
    const [bounceRateData, setBounceRateData] = useState();
    const [totalActiveUsersData, setTotalActiveUsersData] = useState();

    const [bounceRateAndActiveUserDataIsLoading, setBounceRateAndActiveUserDataIsLoading] = useState(true);

    function getBounceRateAndActiveUserData(startDate, endDate) {

        axios.get(ReportBaseUrl+"data-google-analytics/index.php?report=BounceRate_and_activeUsers&start_date="+startDate+"&end_date="+endDate)
        .then((res) =>{
            if(res.status === 200){

                let responce = res.data;
                setBounceRateData( Number(responce["bounceRate"]).toFixed(2) );
                setTotalActiveUsersData(Number(responce["activeUsers"]));
                setBounceRateAndActiveUserDataIsLoading(false);

            }else{
                console.log('error');
            }
        });
        
    }
    /*************** End Bounce Rate **************/

    /************* Active Users By Date ****************/
    const [activeUsersByDateData, setActiveUsersByDateData] = useState();

    const [activeUsersByDateDataIsLoading, setActiveUsersByDateDataIsLoading] = useState(true);
    
    const activeUsersByDateOptions = {
        // responsive: true,
        maintainAspectRatio : false,
        plugins: {
            legend: {
                display: false,
                color: 'white',
            },
            title: {
                display: true,
            },
        },
    };
    
    function getActiveUsersByDateData(startDate, endDate) {

        axios.get(ReportBaseUrl+"data-google-analytics/index.php?report=ActiveUsers_by_date&start_date="+startDate+"&end_date="+endDate)
        .then((res) =>{
            if(res.status === 200){

                let responce = res.data;

                if(Array.isArray(responce) && responce.length != 0){

                    let labels = [];
                    let data = [];

                    
                    responce.forEach(element => {
                        labels.push(element.label);
                        data.push(element.value);
                    });
                    
                    let chartdata = {
                        labels,
                        datasets: [
                        {
                            label: 'Active Users',
                            data: data,
                            borderColor: 'rgb(74, 221, 166)',
                            backgroundColor: 'rgb(74, 221, 166)',
                        }
                        ],
                    };
                    

                    setActiveUsersByDateData( chartdata );
                    setActiveUsersByDateDataIsLoading(false);
                }
                            
            }else{
                console.log('error');
            }
        });
        
    }

    /*************** End Active Users By Date **************/

    /************* traffic source ****************/
    const [trafficSourceData, setTrafficSourceData] = useState();

    const [trafficSourceDataIsLoading, setTrafficSourceDataIsLoading] = useState(true);

    function getTrafficSourceData(startDate, endDate) {

        axios.get(ReportBaseUrl+"data-google-analytics/index.php?report=traffic_source&start_date="+startDate+"&end_date="+endDate)
        .then((res) =>{
            if(res.status === 200){

                let responce = res.data;
                if(responce){
                    setTrafficSourceData( responce );
                    setTrafficSourceDataIsLoading(false);
                }
                        
            }else{
                console.log('error');
            }
        });
        
    }

    /*************** End traffic source **************/

    /************* traffic source details ****************/
    const [trafficSourceDetailsData, setTrafficSourceDetailsData] = useState();

    const [trafficSourceDetailsDataIsLoading, setTrafficSourceDetailsDataIsLoading] = useState(true);

    function getTrafficSourceDetailsData(startDate, endDate) {

        axios.get(ReportBaseUrl+"data-google-analytics/index.php?report=traffic_source_details&start_date="+startDate+"&end_date="+endDate)
        .then((res) =>{
            if(res.status === 200){

                let responce = res.data;
                if(responce){
                    setTrafficSourceDetailsData( responce );
                    setTrafficSourceDetailsDataIsLoading(false);
                    setDateLoadingData(false);
                }
                        
            }else{
                console.log('error');
            }
        });
        
    }

    /*************** End traffic source details **************/

    /************* Active Conversion by destination ****************/
    const [conversionByDestinationData, setConversionByDestinationData] = useState();
    const [conversionByDestinationDataIsLoading, setConversionByDestinationDataIsLoading] = useState(true);

    const conversionByDestinationOptions = {
        indexAxis: 'y',
        elements: {
            bar: {
            borderWidth: 2,
            },
        },
        responsive: true,
        maintainAspectRatio : false,
        plugins: {
            legend: {
                display: false,
                color: 'white',
            }
        },
    };
    
    function getConversionByDestinationData(startDate, endDate) {

        axios.get(ReportBaseUrl+"data-google-analytics/index.php?report=Conversion_by_destination&start_date="+startDate+"&end_date="+endDate)
        .then((res) =>{
            if(res.status === 200){

                let responce = res.data;

                if(Array.isArray(responce) && responce.length != 0){
                    let labels = [];
                    let data = [];

                    responce.forEach(element => {
                        labels.push(element.label +" ("+ element.value +")");
                        data.push(element.value);
                    });
                    
                    let chartdata = {
                        labels,
                        datasets: [
                        {
                            label: 'Active Users',
                            data: data,
                            borderColor: 'rgb(74, 221, 166)',
                            backgroundColor: 'rgb(74, 221, 166)',
                        }
                        ],
                    };

                    setConversionByDestinationData( chartdata );
                    setConversionByDestinationDataIsLoading(false);
                    
                }
                        
            }else{
                console.log('error');
            }
        });
        
    }

    /*************** End Active User By Country **************/

    /************* Chart 7 ****************/
    const [facebookData, setfacebookData] = useState(null);

    function getfacebookData(startDate, endDate) {

        axios.get(ReportBaseUrl+"data-facebook/index.php?start_date="+startDate+"&end_date="+endDate)
        .then((res) =>{
            if(res.status === 200){

                let responce = res.data;
                if(!responce.hasOwnProperty('error')){
                //    let singleRow = responce.data.reverse();
                    setfacebookData( responce );
                }
                        
            }else{
                console.log('error');
            }
        });
        
    }

    /*************** End Chart 7 **************/

    useEffect(() => {
        dispatch(listLeads());
        dispatch(pathListner('/'));
    }, [])

    useEffect(() => {

        getLeadsBySource();

    }, [trafficSourceDetailsData])
  
    useEffect(() => {

        if(startDate && endDate){
            let start_date = moment(startDate).format('YYYY-MM-DD');
            let end_date = moment(endDate).format('YYYY-MM-DD');

            setDateLoadingData(true);

            /*
            setTimeout(() => {
                getTrafficSourceDetailsData(start_date, end_date);
                setTrafficSourceDetailsDataIsLoading(true);
            }, 0);

            setTimeout(() => {
                getActiveUsersByDateData(start_date, end_date);
                setActiveUsersByDateDataIsLoading(true);
            }, 1000);

            // setTimeout(() => {
            //     getactiveUserByDeviceData(start_date, end_date);
            //     setActiveUserByDeviceDataIsLoading(true);
            // }, 2000);

            setTimeout(() => {
                getConversionByDestinationData(start_date, end_date);
                setConversionByDestinationDataIsLoading(true);
            }, 2000);

            setTimeout(() => {
                getActiveUserByCountryData(start_date, end_date);
                setActiveUserByCountryDataIsLoading(true);
            }, 3000);

            setTimeout(() => {
                getBounceRateAndActiveUserData(start_date, end_date);
                setBounceRateAndActiveUserDataIsLoading(true);
            }, 4000);

            setTimeout(() => {
                getTrafficSourceData(start_date, end_date);
                setTrafficSourceDataIsLoading(true);
            }, 5000);

            getfacebookData(start_date, end_date);
            */

        }
        

    }, [dateRange])
    
    return (
        <React.Fragment>
            <div className={dateLoadingData ? "report-date report-date-loading" : "report-date"} >
                <DatePicker
                    dateFormat="dd/MM/yyyy"
                    selectsRange={true}
                    startDate={startDate}
                    endDate={endDate}
                    onChange={(update) => {
                        setDateRange(update);
                    }}
                />

                {/*
                <DateRange
                    editableDateInputs={true}
                    onChange={item => setDate([item.selection])}
                    moveRangeOnFirstSelection={false}
                    ranges={date}
                />
                */}
            </div>
            <div className="vertical-modern-dashboard">
                <div className="row">
                    <div className="col s12 m3 card-width">
                        <div className="card border-radius-9 cart-leads">
                            <h6 className="card-title mt-0 display-flex">Total leads website</h6>
                            <div className="card-content center">
                                <h3 className="white-text lighten-4">{ totalLeadsBySourceData }</h3>
                                {/* <p className="white-text lighten-4">Leads</p> */}
                            </div>
                        </div>
                    </div>

                    <div className={leadsBySourceData ? "col s12 m5" : "card-loading col s12 m5"}>
                        <div className="card border-radius-9 border-radius-9 animate fadeUp cart-activeUserByDevice">
                            <div className="card-content" >
                                <h6 className="card-title mt-0">Leads by sources</h6>
                                { leadsBySourceData ? ( <Bar options={leadsBySourceOptions} data={leadsBySourceData}  /> ) : ""}
                            </div>
                        </div>
                    </div>

                    <div className={conversionByDestinationDataIsLoading ? "col s12 m4 card-loading" : "col s12 m4"}>
                        <div className="card border-radius-9 animate fadeLeft cart-conversionByDestination">
                            <div className="card-content">
                                <h6 className="card-title mt-0 display-flex">
                                    <img className="card-icon" src="/app-assets/images/icon/google-analytics.svg" alt="" />
                                    Conversion by destination
                                </h6>
                                { conversionByDestinationData ? ( <Bar options={conversionByDestinationOptions} data={conversionByDestinationData} /> ) : ""}
                            </div>
                        </div>
                    </div>

                    {/* <div className={activeUserByDeviceDataIsLoading ? "col s12 m3 card-loading":"col s12 m3"} >
                        <div className="card border-radius-9 border-radius-9 animate fadeUp cart-activeUserByDevice">
                            <div className="card-content" >
                                <h6 className="card-title mt-0">
                                    <img className="card-icon" src="/app-assets/images/icon/google-analytics.svg" alt="" />
                                    Active users by device
                                </h6>
                                { activeUserByDeviceData ? ( <Pie options={activeUserByDeviceOptions} data={activeUserByDeviceData}  /> ) : ""}
                            </div>
                        </div>
                    </div> */}

                </div>
                <div className="row">

                    <div className={activeUsersByDateDataIsLoading ? "col s12 m6 card-loading" : "col s12 m6"}>
                        <div className="card border-radius-9 animate fadeLeft cart-activeUsersByDate">
                            <div className="card-content">
                                <h6 className="card-title mt-0 display-flex">
                                    <img className="card-icon" src="/app-assets/images/icon/google-analytics.svg" alt="" />
                                    Active users
                                </h6>
                                { activeUsersByDateData ? ( <Line options={activeUsersByDateOptions} data={activeUsersByDateData} /> ) : ""}
                            </div>
                        </div>
                    </div>

                    <div className={activeUserByCountryDataIsLoading ? "col s12 m4 card-loading" : "col s12 m4"}>
                        <div className="card border-radius-9 animate fadeLeft cart-activeUserByCountry">
                            <div className="card-content">
                                <h6 className="card-title mt-0 display-flex">
                                    <img className="card-icon" src="/app-assets/images/icon/google-analytics.svg" alt="" />
                                    Active users by country
                                </h6>
                                { activeUserByCountryData ? ( <Bar options={activeUserByCountryOptions} data={activeUserByCountryData} /> ) : ""}
                            </div>
                        </div>
                    </div>
                    
                    <div className={bounceRateAndActiveUserDataIsLoading ? "col s12 m2 card-width card-loading" : "col s12 m2 card-width"}>

                        <div className="card border-radius-9 cart-conversions">
                            <img className="floaty-card-icon" src="/app-assets/images/icon/google-analytics.svg" alt="" />
                            <div className="card-content center">
                            <h3 className="white-text lighten-4">{ totalActiveUsersData ? numberFormater(totalActiveUsersData) : ""}</h3>
                            <p className="white-text lighten-4">Total visites</p>
                            </div>
                        </div>

                        <div className="card border-radius-9 cart-bounceRate">
                            <img className="floaty-card-icon" src="/app-assets/images/icon/google-analytics.svg" alt="" />
                            <div className="card-content center">
                            <h3 className="white-text lighten-4">{ bounceRateData ? bounceRateData : ""}</h3>
                            <p className="white-text lighten-4">Bounce rate</p>
                            </div>
                        </div>

                    </div>

                </div>

                <div className="row">

                    <div className={trafficSourceDetailsDataIsLoading ? "col s12 m6 card-loading" : "col s12 m6"}>
                        <div className="card border-radius-9 subscriber-list-card animate fadeRight cart-trafficSourceDetails">
                            <div className="card-content pb-1">
                            <h4 className="card-title mb-0">
                                <img className="card-icon" src="/app-assets/images/icon/google-analytics.svg" alt="" />
                                Traffic by sources
                            </h4>
                            </div>
                            <div className="table-container">
                                <table className="subscription-table responsive-table highlight">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th></th>
                                            <th>Users</th>
                                            {/* <th>Sessions</th> */}
                                            <th>Convertions</th>
                                            <th>Engagement rate</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {trafficSourceDetailsData ? Object.keys(trafficSourceDetailsData).map(function(index){
                                        return ( 
                                            <tr key={"url"+index}>
                                                <th></th>
                                                <td>{index}</td>
                                                <td>{numberFormater(trafficSourceDetailsData[index][0])}</td>
                                                {/* <td>{trafficSourceDetailsData[index][1]}</td> */}
                                                <td>{trafficSourceDetailsData[index][2]? numberFormater(trafficSourceDetailsData[index][2]): 0}</td>
                                                <td>{trafficSourceDetailsData[index][3]? parseFloat(trafficSourceDetailsData[index][3]).toFixed(2): 0}%</td>
                                            </tr>
                                        );
                                    }) : null} 

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    <div className={trafficSourceDataIsLoading ? "col s12 m6 card-loading" : "col s12 m6"}>
                        <div className="card border-radius-9 subscriber-list-card animate fadeRight cart-trafficSource">
                            <div className="card-content pb-1">
                            <h4 className="card-title mb-0">
                                <img className="card-icon" src="/app-assets/images/icon/google-analytics.svg" alt="" />
                                Traffic by channels
                            </h4>
                            </div>
                            <div className="table-container">
                                <table className="subscription-table responsive-table highlight">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th></th>
                                            <th>Users</th>
                                            {/* <th>Sessions</th> */}
                                            <th>Convertions</th>
                                            <th>Engagement rate</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {trafficSourceData ? Object.keys(trafficSourceData).map(function(index){
                                        return ( 
                                            <tr key={"url"+index}>
                                                <th></th>
                                                <td>{index}</td>
                                                <td>{numberFormater(trafficSourceData[index][0])}</td>
                                                {/* <td>{trafficSourceData[index][1]}</td> */}
                                                <td>{trafficSourceData[index][2]? numberFormater(trafficSourceData[index][2]): 0}</td>
                                                <td>{trafficSourceData[index][3]? parseFloat(trafficSourceData[index][3]).toFixed(2): 0}%</td>
                                            </tr>
                                        );
                                    }) : null} 

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                </div>

                {facebookData ? facebookData.map(function(item, i){
                    return ( 
                    <div className="row" key={"facebook"+i}>
                        <div className="col s12 m12 l12">
                            <div className="card border-radius-9 subscriber-list-card animate fadeRight">
                                <div className="card-content pb-1">
                                    <h4 className="card-title">
                                        <img className="card-icon facebook" src="/app-assets/images/icon/facebook-icon.svg" alt="" />
                                        Facebook report
                                    </h4>
                                    <h4 className="card-title mb-0"> { item[0].campaign_name } </h4> 
                                </div>
                                
                            </div>

                            <div className="row">

                                    <div className="col s12 m3 card-width">
                                        <div className="card border-radius-9 cart-leads">
                                            <img className="floaty-card-icon facebook" src="/app-assets/images/icon/facebook-icon.svg" alt="" />
                                            <div className="card-content center">
                                            <h3 className="white-text lighten-4">{ item[0].leads? numberFormater(item[0].leads) : 0 }</h3>
                                            <p className="white-text lighten-4">Leads</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col s12 m3 card-width">
                                        <div className="card border-radius-9 cart-leads">
                                            <img className="floaty-card-icon facebook" src="/app-assets/images/icon/facebook-icon.svg" alt="" />
                                            <div className="card-content center">
                                            <h3 className="white-text lighten-4">{ item[0].impressions? numberFormater(item[0].impressions) : 0 }</h3>
                                            <p className="white-text lighten-4">Impressions</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col s12 m3 card-width">
                                        <div className="card border-radius-9 cart-leads">
                                            <img className="floaty-card-icon facebook" src="/app-assets/images/icon/facebook-icon.svg" alt="" />
                                            <div className="card-content center">
                                            <h3 className="white-text lighten-4">{ item[0].ctr? parseFloat(item[0].ctr).toFixed(2) : 0 }%</h3>
                                            <p className="white-text lighten-4">CTR</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col s12 m3 card-width">
                                        <div className="card border-radius-9 cart-leads">
                                            <img className="floaty-card-icon facebook" src="/app-assets/images/icon/facebook-icon.svg" alt="" />
                                            <div className="card-content center">
                                            <h3 className="white-text lighten-4">{item[0].clicks? numberFormater(item[0].clicks) : 0 }</h3>
                                            <p className="white-text lighten-4">Clicks</p>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                        </div>
                    </div>
                    );
                }) : null }  
                
            </div>
        </React.Fragment>
    );
}
