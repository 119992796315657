import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { pathListner } from '../../actions/pathActions';
import { listPosts, savePosts, selectedPost, postByLang } from '../../actions/postsActions';
import { listPostTemplates } from '../../actions/postTemplatesActions';
import { listLanguages, currentLanguage } from '../../actions/languagesActions';
import FileManagerModal from '../FilesManager/FileManagerModal';
import { toggleFilesManagerModal, selectedMultiFiles } from '../../actions/fileManagerActions';
import PostSidebar from './PostSidebar';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ReactSortable } from "react-sortablejs";
import { assetsURL } from "../../config/Settings"
import Loading from '../loader/Loading';
import _ from "lodash";
import { _trans } from '../../actions/locoBOActions';

export default function PostsForm(props) {

    const { locosBO, loading: locosBOLoading, error: locosBOError } = useSelector(state => state.listLocosBO)

    const dispatch = useDispatch();

    const [idPost, setidPost] = useState(null);
    const [loading, setLoading] = useState(false);
    const [postTitle, setPostTitle] = useState('');
    const [postSlug, setPostSlug] = useState('');
    const [postStatus, setPostStatus] = useState('publish');
    const [postTemplateID, setPostTemplateID] = useState('');
    const [postTemplatesFields, setPostTemplatesFields] = useState([]);
    const [postFields, setPostFields] = useState([]);
    const [thisPost, setThisPost] = useState(null);

    const [selectedImgBtn, setSelectedImgBtn] = useState(null);
    const [selectedRepeteurImgBtn, setSelectedRepeteurImgBtn] = useState(-1);
    const [selectedRepeteurItemImgBtn, setSelectedRepeteurItemImgBtn] = useState(null);

    const [selectedGalleryBtn, setSelectedGalleryBtn] = useState(null);
    const [selectedRepeteurGalleryBtn, setSelectedRepeteurGalleryBtn] = useState(-1);
    const [selectedRepeteurItemGalleryBtn, setSelectedRepeteurItemGalleryBtn] = useState(null);

    const fileSelected = useSelector(state => state.selectedFile);
    const fileSelectedMulti = useSelector(state => state.selectedMultiFiles);

    let postSelected = useSelector(state => state.selectedPost);

    const postTemplatesList = useSelector(state => state.postTemplatesList);
    const { postTemplates,
        loading: postTemplatesLoading,
        error: postTemplatesError } = postTemplatesList;

    const postsList = useSelector(state => state.postsList);
    const { posts,
        loading: postLoading,
        error: postError } = postsList;

    const { currentLang } = useSelector(state => state.currentLang);
    const { selectedPostCategoriesItems } = useSelector(state => state.selectedPostCategoriesItems);

    const languagesList = useSelector(state => state.languagesList);
    const { languages, loading: langsLoading, error: langsError } = languagesList;

    const postsLang = useSelector(state => state.postsLang);
    const { post: langPost,
        loading: postLangLoading,
        error: postLangError } = postsLang;

    const [langID, setLangID] = useState("");

    let newPostTemplateID = null;

    const filesManagerToggleModal = useSelector(state => state.toggleFilesManagerModal);


    useEffect(() => {

        window.initPlugins();

        if (!postTemplates) {
            dispatch(listPostTemplates());
            dispatch(currentLanguage());
            dispatch(listLanguages());
        }

        let post_type_id = props.match.params.id_type;
        let post_id = props.match.params.id_post;

        if (!postSelected.posts) {
            if (postsList.posts) {

                if (postsList.posts.length == 0) {
                    dispatch(listPosts(post_type_id));
                } else {
                    let selecedPost = postsList.posts.find(item => item.id == post_id);
                    setThisPost(selecedPost);
                }

            }
        } else {
            setThisPost(postSelected.posts);
        }

        if (!langID && currentLang) {
            setLangID(currentLang.id);
        }

        dispatch(pathListner('/posts/' + post_type_id));

        if (thisPost) {

            setidPost(thisPost.id);
            setPostTitle(thisPost.title);
            setPostSlug(thisPost.slug);
            setPostTemplateID(thisPost.id_post_templates);
            setPostFields(JSON.parse(thisPost.fields));

            if (postTemplates) {
                onChangePostTemplate(thisPost.id_post_templates);
            }

        } else {

            setidPost('');
            setPostTitle('');
            setPostSlug('');
            setPostTemplateID('');
            setPostFields([]);
        }


    }, [idPost, thisPost, postTemplates, langPost]);
    // },[postsList.posts, idPost, thisPost, postTemplates, langPost]);
    // }, [postSelected, postTemplates, props, langPost, idPost]);


    useEffect(() => {

        if (fileSelected.file && postTemplatesFields.length && selectedImgBtn) {
            const values = [...postTemplatesFields];

            let index = values.findIndex((itmInner) => itmInner.id === selectedImgBtn);

            if (selectedRepeteurImgBtn != -1) {
                let item_index = values[index].fields[selectedRepeteurImgBtn].findIndex((itmInner) => itmInner.id === selectedRepeteurItemImgBtn);

                values[index].fields[selectedRepeteurImgBtn][item_index].value = fileSelected.file.path;
            } else {
                values[index].value = fileSelected.file.path;
            }

            setPostTemplatesFields(values);

            setSelectedImgBtn(null);
            setSelectedRepeteurImgBtn(-1);
            setSelectedRepeteurItemImgBtn(null);
        }

    }, [fileSelected]);

    useEffect(() => {

        if (fileSelectedMulti.files && postTemplatesFields.length && selectedGalleryBtn && filesManagerToggleModal.toggle) {

            const values = [...postTemplatesFields];

            let index = values.findIndex((itmInner) => itmInner.id === selectedGalleryBtn);

            if (selectedRepeteurGalleryBtn != -1) {
                let item_index = values[index].fields[selectedRepeteurGalleryBtn].findIndex((itmInner) => itmInner.id === selectedRepeteurItemGalleryBtn);

                values[index].fields[selectedRepeteurGalleryBtn][item_index].value = fileSelectedMulti.files;
            } else {
                values[index].value = fileSelectedMulti.files;
            }

            setPostTemplatesFields(values);

            // setSelectedGalleryBtn(null);
            // setSelectedRepeteurGalleryBtn(-1);
            // setSelectedRepeteurItemGalleryBtn(null);
        }

    }, [fileSelectedMulti]);


    const onChangePostName = (e) => {
        let postName = e.target.value;
        setPostTitle(postName);
        let postSlug = postName.toLowerCase()
            .replace(/ /g, '-')
            .replace(/[^\w-]+/g, '');
        setPostSlug(postSlug);
    }

    const onChangePostSlug = (e) => {
        let postSlug = e.target.value;
        postSlug = postSlug.toLowerCase()
            .replace(/ /g, '-')
            .replace(/[^\w-]+/g, '');
        setPostSlug(postSlug);
    }

    const onChangePostStatus = (status) => {
        setPostStatus(status);
    }

    const onChangePostTemplate = (value) => {

        setPostTemplateID(value);

        newPostTemplateID = value;

        let postTemplates_data = [];

        if (value) {


            let post_template_id = parseInt(value);

            if (postTemplates) {
                postTemplates_data = postTemplates.filter(item => item.id === post_template_id);
            }

            if (postTemplates_data.length) {
                let postTemplatesFieldsData = JSON.parse(postTemplates_data[0].post_fields_fields);

                for (let index = 0; index < postTemplatesFieldsData.length; index++) {

                    if (postTemplatesFieldsData[index].type_input === 'Select') {
                        let select_option = postTemplatesFieldsData[index].select_option;

                        select_option = select_option.split("\n");

                        postTemplatesFieldsData[index].select_option = select_option;
                    }//Select

                    if (postTemplatesFieldsData[index].type_input === 'CheckBox') {
                        let checkbox_option = postTemplatesFieldsData[index].checkbox_option;

                        checkbox_option = checkbox_option.split("\n");

                        let options = [];

                        for (let index = 0; index < checkbox_option.length; index++) {
                            let options_item = {}
                            options_item.value = checkbox_option[index];
                            options_item.state = false;
                            options.push(options_item);
                        }

                        postTemplatesFieldsData[index].options = options;
                    }//CheckBox

                    if (postTemplatesFieldsData[index].type_input === 'Radio') {
                        let radio_option = postTemplatesFieldsData[index].radio_option;

                        radio_option = radio_option.split("\n");

                        let options = [];

                        for (let index = 0; index < radio_option.length; index++) {
                            let options_item = {}
                            options_item.value = radio_option[index];
                            options_item.state = false;
                            options.push(options_item);
                        }

                        postTemplatesFieldsData[index].options = options;
                    }//Radio

                    if (postTemplatesFieldsData[index].type_input === 'Repeteur') {
                        let repeteurFields = postTemplatesFieldsData[index].fields;

                        postTemplatesFieldsData[index].fields = [];

                        postTemplatesFieldsData[index].fields.push(repeteurFields);

                        for (let Rindex = 0; Rindex < repeteurFields.length; Rindex++) {

                            if (repeteurFields[Rindex].type_input === 'Select') {
                                let select_option = postTemplatesFieldsData[index].fields[0][Rindex].select_option;

                                select_option = select_option.split("\n");

                                postTemplatesFieldsData[index].fields[0][Rindex].select_option = select_option;
                            }//Repeteur Select

                            if (repeteurFields[Rindex].type_input === 'CheckBox') {
                                let checkbox_option = postTemplatesFieldsData[index].fields[0][Rindex].checkbox_option;

                                checkbox_option = checkbox_option.split("\n");

                                let options = [];

                                for (let i = 0; i < checkbox_option.length; i++) {
                                    let options_item = {}
                                    options_item.value = checkbox_option[i];
                                    options_item.state = false;
                                    options.push(options_item);
                                }

                                postTemplatesFieldsData[index].fields[0][Rindex].options = options;
                            }//Repeteur CheckBox

                            if (repeteurFields[Rindex].type_input === 'Radio') {

                                let radio_option = postTemplatesFieldsData[index].fields[0][Rindex].radio_option;

                                radio_option = radio_option.split("\n");

                                let options = [];

                                for (let i = 0; i < radio_option.length; i++) {
                                    let options_item = {}
                                    options_item.value = radio_option[i];
                                    options_item.state = false;
                                    options.push(options_item);
                                }

                                postTemplatesFieldsData[index].fields[0][Rindex].options = options;
                            }//Repeteur Radio

                        }//Repeteur loop

                    }//Repeteur

                }//fields loop

                postTemplates_data = [...postTemplatesFieldsData];

                setPostTemplatesFields(postTemplates_data);

            }

            if (idPost) {

                if (postTemplateID === post_template_id) {

                    let PostFieldsData = postFields;
                    if (!Array.isArray(PostFieldsData)) {
                        PostFieldsData = JSON.parse(PostFieldsData);
                    }

                    if (langPost?.length && langPost[0].id_lang === parseInt(langID) && langPost[0].fields) {
                        PostFieldsData = JSON.parse(langPost[0].fields);
                        setPostTitle(langPost[0].title);
                        setPostSlug(langPost[0].slug);
                    }

                    let PostTypeFieldsData = postTemplates_data;

                    let merged = [];

                    for (let i = 0; i < PostTypeFieldsData.length; i++) {

                        if (typeof PostFieldsData === "string") {
                            PostFieldsData = JSON.parse(PostFieldsData);
                        }

                        let item_index = PostFieldsData.findIndex((itmInner) => itmInner.id === PostTypeFieldsData[i].id);

                        if (PostTypeFieldsData[i].type_input === "Repeteur") {

                            let merged_repeteur = [];

                            merged_repeteur = {
                                "id": PostTypeFieldsData[i].id,
                                "titre_input": PostTypeFieldsData[i].titre_input,
                                "type_input": PostTypeFieldsData[i].type_input,
                                "name_input": PostTypeFieldsData[i].name_input,
                                "fields": []
                            }

                            if (PostFieldsData[item_index] && PostFieldsData[item_index].fields) {

                                for (let j = 0; j < PostFieldsData[item_index].fields.length; j++) {

                                    let merged_repeteur_fields_item = [];
                                    for (let x = 0; x < PostTypeFieldsData[i].fields[0].length; x++) {

                                        let repeteur_item_index = PostFieldsData[item_index].fields[j].findIndex((itmInner) => itmInner.id === PostTypeFieldsData[i].fields[0][x].id);

                                        merged_repeteur_fields_item.push({
                                            ...PostTypeFieldsData[i].fields[0][x],
                                            ...PostFieldsData[item_index].fields[j][repeteur_item_index]
                                        });

                                    }

                                    merged_repeteur.fields.push(merged_repeteur_fields_item);

                                }
                            }

                            merged.push(merged_repeteur);

                        } else {
                            merged.push({
                                ...PostTypeFieldsData[i],
                                ...(PostFieldsData.filter((itmInner) => itmInner.id === PostTypeFieldsData[i].id)[0])
                            });
                        }

                    }

                    setPostTemplatesFields(merged);
                }
            }
        }

    }

    const onChangeLangs = (value) => {

        let id_lang = value;
        setLoading(true);
        setLangID(id_lang);

        if (idPost) {
            dispatch(postByLang(idPost, id_lang, (response) => {
                setLoading(false);
            }));
        }

    }

    const handleChangeInput = (event, id) => {

        const values = [...postTemplatesFields];
        const { name, value } = event.target;

        let index = values.findIndex((itmInner) => itmInner.id === id);
        values[index].value = value;

        setPostTemplatesFields(values);

    }

    const handleChangeCheckbox = (event, id, optionidx) => {

        const values = [...postTemplatesFields];

        let index = values.findIndex((itmInner) => itmInner.id === id);
        values[index].options[optionidx].state = event.target.checked;

        setPostTemplatesFields(values);
    }

    const handleChangeRadio = (event, id, optionidx) => {

        if (event.target.checked) {
            const values = [...postTemplatesFields];

            let index = values.findIndex((itmInner) => itmInner.id === id);
            for (let i = 0; i < values[index].options.length; i++) {
                values[index].options[i].state = false;
            }

            values[index].options[optionidx].state = event.target.checked;

            setPostTemplatesFields(values);
        }
    }

    const handleChangeWysiwyg = (event, id, editor) => {

        if (!newPostTemplateID) {

            const data = editor.getData();

            const values = [...postTemplatesFields];

            let index = values.findIndex((itmInner) => itmInner.id === id);
            values[index].value = data;

            setPostTemplatesFields(values);
        }
    }

    const handleRepeteurChangeInput = (event, id, Ridx, FRIidx) => {

        const values = [...postTemplatesFields];
        const { name, value } = event.target;

        let index = values.findIndex((itmInner) => itmInner.id === id);
        let item_index = values[index].fields[Ridx].findIndex((itmInner) => itmInner.id === FRIidx);

        values[index].fields[Ridx][item_index].value = value;

        setPostTemplatesFields(values);

    }

    const handleRepeteurChangeCheckbox = (event, id, optionidx, Ridx, FRIidx) => {

        const values = [...postTemplatesFields];

        let index = values.findIndex((itmInner) => itmInner.id === id);
        let item_index = values[index].fields[Ridx].findIndex((itmInner) => itmInner.id === FRIidx);

        values[index].fields[Ridx][item_index].options[optionidx].state = event.target.checked;

        setPostTemplatesFields(values);
    }

    const handleRepeteurChangeRadio = (event, id, optionidx, Ridx, FRIidx) => {

        if (event.target.checked) {
            const values = [...postTemplatesFields];

            let index = values.findIndex((itmInner) => itmInner.id === id);
            let item_index = values[index].fields[Ridx].findIndex((itmInner) => itmInner.id === FRIidx);

            for (let i = 0; i < values[index].fields[Ridx][item_index].options.length; i++) {
                values[index].fields[Ridx][item_index].options[i].state = false;
            }

            values[index].fields[Ridx][item_index].options[optionidx].state = event.target.checked;

            setPostTemplatesFields(values);
        }
    }

    const handleRepeteurChangeWysiwyg = (event, id, editor, Ridx, FRIidx) => {

        if (!newPostTemplateID) {

            const data = editor.getData();
            const values = [...postTemplatesFields];

            let index = values.findIndex((itmInner) => itmInner.id === id);
            let item_index = values[index].fields[Ridx].findIndex((itmInner) => itmInner.id === FRIidx);

            values[index].fields[Ridx][item_index].value = data;

            setPostTemplatesFields(values);
        }
    }

    const openFilesManagerModalImg = (id, Ridx = -1, FRIidx = null) => {

        setSelectedImgBtn(id);
        setSelectedRepeteurImgBtn(Ridx);
        setSelectedRepeteurItemImgBtn(FRIidx);
        dispatch(toggleFilesManagerModal(true));

    }

    const openFilesManagerModalGallery = (gallery, id, Ridx = -1, FRIidx = null) => {

        setSelectedGalleryBtn(id);
        setSelectedRepeteurGalleryBtn(Ridx);
        setSelectedRepeteurItemGalleryBtn(FRIidx);
        dispatch(toggleFilesManagerModal(true));

        if (gallery) {
            dispatch(selectedMultiFiles(gallery));
        }

    }

    const addRepeteurItem = (event, idx) => {

        if (postTemplates) {

            let postTemplatesFieldsData = postTemplates.filter(item => item.id === parseInt(postTemplateID));

            postTemplatesFieldsData = JSON.parse(postTemplatesFieldsData[0].post_fields_fields);

            let repeteurFields = postTemplatesFieldsData[idx].fields;

            let repeteurFieldsSelectIndex = repeteurFields.findIndex((itmInner) => itmInner.type_input === 'Select');

            if (repeteurFieldsSelectIndex != -1) {
                let select_option = postTemplatesFieldsData[idx].fields[repeteurFieldsSelectIndex].select_option;

                select_option = select_option.split("\n");

                postTemplatesFieldsData[idx].fields[repeteurFieldsSelectIndex].select_option = select_option;
            }

            let repeteurFieldsChackboxIndex = repeteurFields.findIndex((itmInner) => itmInner.type_input === 'CheckBox');

            if (repeteurFieldsChackboxIndex != -1) {
                let checkbox_option = postTemplatesFieldsData[idx].fields[repeteurFieldsChackboxIndex].checkbox_option;

                checkbox_option = checkbox_option.split("\n");

                let options = [];

                for (let index = 0; index < checkbox_option.length; index++) {
                    let options_item = {}
                    options_item.value = checkbox_option[index];
                    options_item.state = false;
                    options.push(options_item);
                }

                postTemplatesFieldsData[idx].fields[repeteurFieldsChackboxIndex].options = options;
            }

            let repeteurFieldsRadioIndex = repeteurFields.findIndex((itmInner) => itmInner.type_input === 'Radio');

            if (repeteurFieldsRadioIndex != -1) {
                let radio_option = postTemplatesFieldsData[idx].fields[repeteurFieldsRadioIndex].radio_option;

                radio_option = radio_option.split("\n");

                let options = [];

                for (let index = 0; index < radio_option.length; index++) {
                    let options_item = {}
                    options_item.value = radio_option[index];
                    options_item.state = false;
                    options.push(options_item);
                }

                postTemplatesFieldsData[idx].fields[repeteurFieldsRadioIndex].options = options;
            }

            const values = [...postTemplatesFields];

            values[idx].fields.push(
                postTemplatesFieldsData[idx].fields
            );

            setPostTemplatesFields(values);
        }

    }

    const deleteRepeteurItem = (event, id, Ridx) => {

        let postItems = [...postTemplatesFields];

        let index = postItems.findIndex((itmInner) => itmInner.id === id);
        postItems[index].fields.splice(Ridx, 1);

        setPostTemplatesFields(postItems);
    }

    const deleteGalleryItem = (imgItem, id, Ridx = -1, FRIidx = null) => {

        let postItems = [...postTemplatesFields];
        let index = postItems.findIndex((itmInner) => itmInner.id === id);

        if (Ridx === -1) {
            let indexImg = postItems[index].value.findIndex((itmInner) => itmInner === imgItem);

            postItems[index].value.splice(indexImg, 1);

            dispatch(selectedMultiFiles(postItems[index].value));

        } else {
            let item_index = postItems[index].fields[Ridx].findIndex((itmInner) => itmInner.id === FRIidx);
            let indexImg = postItems[index].fields[Ridx][item_index].value.findIndex((itmInner) => itmInner === imgItem);

            postItems[index].fields[Ridx][item_index].value.splice(indexImg, 1);

            dispatch(selectedMultiFiles(postItems[index].fields[Ridx][item_index].value));
        }


        setPostTemplatesFields(postItems);

    }

    const repeteurItemCollapse = (id) => {

        let repeteurItem = document.querySelector(".post-repeteur-item-" + id);

        repeteurItem.classList.toggle("repeteurItemCollapsed");

    }

    const handleSubmit = () => {
        setLoading(true);
        let fields = postTemplatesFields;

        let postData = {};

        postData.id = idPost;
        postData.post_title = postTitle;
        postData.post_slug = postSlug;
        postData.post_status = postStatus;
        postData.id_post_type = props.match.params.id_type;
        postData.id_post_templates = postTemplateID;
        postData.id_lang = langID;
        postData.categories = selectedPostCategoriesItems;
        // postData.fields = fields;
        postData.fields = JSON.stringify(fields);


        dispatch(savePosts(postData, (response) => {
            // history.push('/posts/' + props.match.params.id);
            if (!idPost) {
                postData.id = response[0];
                dispatch(selectedPost(postData));
            }
            setLoading(false);
            toast.success(_trans('Saved', locosBO));
        }));


    }

    return <React.Fragment>

        <FileManagerModal />

        <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
        />

        {/* { postTemplatesFields.length > 0 ? (   */}

        <div className="row">
            <div className="content-wrapper-before gradient-45deg-indigo-purple"></div>

            {/* post sidebar  */}
            <PostSidebar post_type_id={props.match.params.id} />
            {/* ./post sidebar  */}

            <div className="content-area content-right">
                <div className="app-wrapper">

                    <div style={{ bottom: 'initial', right: '90px', top: '5px', padding: '0' }} className="fixed-action-btn direction-top">
                        <button onClick={handleSubmit} className="btn-large radient-shadow submit-post" >
                            {/* <i className="material-icons">add_box</i> */}
                            {_trans('Save', locosBO)}
                        </button>
                    </div>

                    <div className="row">
                        <div className="col s12">
                            <div className="card">
                                <div className="card-content row">
                                    <div className="input-field col s12">
                                        <input id="posts_type_name" type="text" className="validate" onChange={onChangePostName} value={postTitle} />
                                        <label htmlFor="posts_type_name" className={postTitle !== '' ? 'active' : ''}>{_trans('Title', locosBO)}</label>
                                    </div>
                                    <div className="input-field col s12">
                                        <input id="posts_type_slug" type="text" className="validate" onChange={onChangePostSlug} value={postSlug} />
                                        <label htmlFor="posts_type_slug" className={postSlug !== '' ? 'active' : ''}>Slug</label>
                                    </div>
                                    <div className="input-field col s4">
                                        <select
                                            name="post_template"
                                            className="validate"
                                            onChange={e => onChangePostTemplate(e.target.value)}
                                            value={postTemplateID}>
                                            <option value="">---</option>
                                            {!postTemplatesLoading &&
                                                postTemplates.map(function (item, i) {
                                                    return <option value={item.id} key={'template' + i} >{item.postTemplates_name}</option>
                                                })
                                            }
                                        </select>
                                        <label htmlFor="posts_type_slug" className="active">{_trans('Template', locosBO)}</label>
                                    </div>
                                    <div className={`input-field col s4 ${idPost ? "" : "isDisabled"}`}>
                                        <select
                                            name="post_lang"
                                            className="validate"
                                            onChange={e => onChangeLangs(e.target.value)}
                                            value={langID}
                                        >
                                            {!langsLoading &&
                                                languages.map(function (item, i) {
                                                    return <option value={item.id} key={'lang' + i} >{item.langs_title}</option>
                                                })
                                            }
                                        </select>
                                        <label htmlFor="post_lang" className="active">{_trans('Language', locosBO)}</label>
                                    </div>
                                    <div className="input-field col s4">
                                        <select
                                            name="post_state"
                                            className="validate"
                                            onChange={e => onChangePostStatus(e.target.value)}
                                            value={postStatus}>
                                            <option value="publish">{_trans('publish', locosBO)}</option>
                                            <option value="draft">{_trans('draft', locosBO)}</option>
                                        </select>
                                        <label htmlFor="posts_type_slug" className="active">{_trans('State', locosBO)}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col s12">
                            <div id="input-fields" className="card card card-default scrollspy">
                                <div className="card-content">

                                    <form id="posts-form">
                                        {postTemplatesFields.map((field, idx) => {
                                            return (
                                                field.type_input === 'Text' ?
                                                    <div className="row" key={'post-form-item' + field.id}>
                                                        <div className="input-field col s12">
                                                            <input
                                                                type="text"
                                                                id={field.name_input + "-" + field.id}
                                                                name={field.name_input}
                                                                value={field.value || ''}
                                                                onChange={e => handleChangeInput(e, field.id)} />
                                                            <label htmlFor={field.name_input + "-" + field.id} className={field.value ? 'active' : ''}>{field.titre_input}</label>
                                                        </div>
                                                    </div>

                                                    : field.type_input === 'Textarea' ?
                                                        <div className="row" key={'post-form-item' + field.id}>
                                                            <div className="input-field col s12">
                                                                <textarea
                                                                    id={field.name_input + "-" + field.id}
                                                                    name={field.name_input}
                                                                    className="materialize-textarea"
                                                                    value={field.value || ''}
                                                                    onChange={e => handleChangeInput(e, field.id)}></textarea>
                                                                <label htmlFor={field.name_input + "-" + field.id} className={field.value ? 'active' : ''}>{field.titre_input}</label>
                                                            </div>
                                                        </div>

                                                        : field.type_input === 'Select' ?
                                                            <div className="row" key={'post-form-item' + field.id}>
                                                                <div className="input-field col s12">
                                                                    <label className="active" htmlFor={field.name_input}>{field.titre_input}</label>
                                                                    <select
                                                                        id={field.name_input}
                                                                        name={field.name_input}
                                                                        defaultValue={field.value || ''}
                                                                        onChange={e => handleChangeInput(e, field.id)} >
                                                                        {Array.isArray(field.select_option) ? field.select_option.map((value, optionidx) => {
                                                                            return (<option key={optionidx} value={value}>{value}</option>)
                                                                        }) : ''}
                                                                    </select>
                                                                </div>
                                                            </div>

                                                            : field.type_input === 'CheckBox' ?
                                                                <div className="row" key={'post-form-item' + field.id}>
                                                                    <div className="col s12">
                                                                        <p className="mb-1">
                                                                            <label className="active" htmlFor={field.name_input}>{field.titre_input}</label>
                                                                        </p>
                                                                        {Array.isArray(field.options) ? field.options.map((option, optionidx) => {
                                                                            return (
                                                                                <p className="mb-1" key={optionidx}>
                                                                                    <label>
                                                                                        <input
                                                                                            type='checkbox'
                                                                                            name={field.name_input}
                                                                                            value={option.value}
                                                                                            checked={option.state}
                                                                                            onChange={e => handleChangeCheckbox(e, field.id, optionidx)} />
                                                                                        <span>{option.value}</span>
                                                                                    </label>
                                                                                </p>
                                                                            )
                                                                        }) : ''}
                                                                    </div>
                                                                </div>

                                                                : field.type_input === 'Radio' ?
                                                                    <div className="row" key={'post-form-item' + field.id}>
                                                                        <div className="col s12">
                                                                            <p className="mb-1">
                                                                                <label className="active" htmlFor={field.name_input}>{field.titre_input}</label>
                                                                            </p>
                                                                            {Array.isArray(field.options) ? field.options.map((option, optionidx) => {
                                                                                return (
                                                                                    <p className="mb-1" key={optionidx}>
                                                                                        <label>
                                                                                            <input
                                                                                                type='radio'
                                                                                                name={field.name_input}
                                                                                                value={option.value}
                                                                                                checked={option.state}
                                                                                                onChange={e => handleChangeRadio(e, field.id, optionidx)} />
                                                                                            <span>{option.value}</span>
                                                                                        </label>
                                                                                    </p>
                                                                                )
                                                                            }) : ''}
                                                                        </div>
                                                                    </div>

                                                                    : field.type_input === 'Image' ?
                                                                        <div className="row" key={'post-form-item' + field.id}>
                                                                            <div className="input-field col s12">
                                                                                <label className="active" htmlFor={field.name_input}>{field.titre_input}</label>
                                                                            </div>

                                                                            <div className="input-field col s12">
                                                                                <input
                                                                                    type="hidden"
                                                                                    name={field.name_input}
                                                                                    value={field.value || ''}
                                                                                    onChange={e => handleChangeInput(e, field.id)} />
                                                                                <button
                                                                                    type="button"
                                                                                    className="btn posts-form-btn"
                                                                                    onClick={e => openFilesManagerModalImg(field.id)} width="200" >image</button>
                                                                                <br />
                                                                                {field.value ? (
                                                                                    <img src={assetsURL + field.value} alt="users avatar" className="z-depth-4 users-form-avatar posts-form-img" height="150" width="200"></img>
                                                                                ) : null}
                                                                            </div>
                                                                        </div>

                                                                        : field.type_input === 'Wysiwyg' ?
                                                                            <div className="row" key={'post-form-item' + field.id}>
                                                                                <div className="input-field col s12">
                                                                                    <label className="active" htmlFor={field.name_input + "-" + field.id}>{field.titre_input}</label>
                                                                                </div>
                                                                                <div className="input-field col s12">
                                                                                    <input
                                                                                        type="hidden"
                                                                                        id={field.name_input}
                                                                                        name={field.name_input}
                                                                                        value={field.value || ''}
                                                                                        onChange={e => handleChangeInput(e, field.id)} />
                                                                                    <CKEditor
                                                                                        editor={ClassicEditor}
                                                                                        data={field.value || ''}
                                                                                        onChange={(event, editor) => {
                                                                                            handleChangeWysiwyg(event, field.id, editor);
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                            </div>

                                                                            : field.type_input === 'ImageGallery' ?
                                                                                <div className="post_repeteur" key={'post-form-item' + field.id} >
                                                                                    <div className="card card-content" style={{ background: 'rgb(30 30 30)' }}>
                                                                                        <div className="post_repeteur_titre"> {field.titre_input} </div>

                                                                                        <div className="row image-gallery-container" >
                                                                                            {field.value ?
                                                                                                <ReactSortable
                                                                                                    handle='.gallery-image-item'
                                                                                                    list={field.value} setList={(data) => {
                                                                                                        let index = postTemplatesFields.findIndex((itmInner) => itmInner.id === field.id);
                                                                                                        let fieldItems = [...postTemplatesFields];
                                                                                                        fieldItems[index].value = data;
                                                                                                        setPostTemplatesFields(fieldItems);

                                                                                                    }}
                                                                                                >
                                                                                                    {field.value.map((imgItem, idx) => {
                                                                                                        return (
                                                                                                            <div className="gallery-image-item" style={{ display: 'inline-block', position: 'relative' }} key={'gallery-image-item-' + field.id + '-' + idx}>
                                                                                                                <button className="btn waves-effect waves-light left gallery-image-item-delete" type="button" name="action" onClick={(e) => deleteGalleryItem(imgItem, field.id)}><i className="material-icons left">delete</i></button>
                                                                                                                <img src={assetsURL + imgItem.path} alt="users avatar" className="z-depth-4 users-form-avatar posts-form-img" height="150" width="200" style={{ margin: '10px' }}></img>
                                                                                                            </div>
                                                                                                        )
                                                                                                    })}
                                                                                                </ReactSortable>
                                                                                                : null}
                                                                                        </div>

                                                                                        <div className="row">
                                                                                            <button type="button" className="btn cyan waves-effect waves-light right" onClick={e => openFilesManagerModalGallery(field.value, field.id)} name="action">
                                                                                                {_trans('Add an image', locosBO)}
                                                                                                <i className="material-icons left">add</i>
                                                                                            </button>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>

                                                                                : field.type_input === 'Repeteur' ?
                                                                                    <div className="post_repeteur" key={'post-form-item' + field.id} >

                                                                                        <div className={`card card-content ${'post-repeteur-item-' + field.id}`} style={{ background: 'rgb(30 30 30)' }}>
                                                                                            <button type="button" className="repeteur-item-collapse btn"
                                                                                                onClick={e => repeteurItemCollapse(field.id)}>
                                                                                                <i className="material-icons close">expand_less</i>
                                                                                                <i className="material-icons open">expand_more</i>
                                                                                            </button>
                                                                                            <div className="post_repeteur_titre"> {field.titre_input} </div>

                                                                                            {field.fields ?
                                                                                                <ReactSortable
                                                                                                    handle='.handle'
                                                                                                    list={field.fields}
                                                                                                    setList={(data) => {

                                                                                                        const hasValue = data.map((item) => {
                                                                                                            item = _.values(item);
                                                                                                            return item.find(item => item.value)
                                                                                                        })

                                                                                                        if (hasValue[0]) {
                                                                                                            let index = postTemplatesFields.findIndex((itmInner) => itmInner.id === field.id);
                                                                                                            let fieldItems = [...postTemplatesFields];

                                                                                                            let values = data.map((value) => {
                                                                                                                delete value.selected;
                                                                                                                return _.values(value);
                                                                                                            })

                                                                                                            fieldItems[index].fields = values;

                                                                                                            setPostTemplatesFields(fieldItems);
                                                                                                        }
                                                                                                    }}
                                                                                                >

                                                                                                    {field.fields.map((fieldRepeteur, Ridx) => {
                                                                                                        delete fieldRepeteur.chosen;
                                                                                                        fieldRepeteur = _.values(fieldRepeteur);
                                                                                                        return (
                                                                                                            <div className="card card-content post_repeteur_item" key={'post-form-item' + field.id + '-' + Ridx}>
                                                                                                                <span className="post-template-handle"><i className="material-icons handle">open_with</i></span>
                                                                                                                <button type="button" className="repeteur-item-close btn"
                                                                                                                    onClick={e => deleteRepeteurItem(e, field.id, Ridx)}><i className="material-icons">close</i></button>

                                                                                                                {fieldRepeteur?.map((fieldRepeteurItem, FRIidx) => {
                                                                                                                    return (
                                                                                                                        <div className="repeteur-item" key={'post-form-item' + field.id + '-' + Ridx + '-' + fieldRepeteurItem.id} data-r={idx + '-' + Ridx + '-' + FRIidx}>
                                                                                                                            {
                                                                                                                                fieldRepeteurItem.type_input === 'Text' ?
                                                                                                                                    <div className="row">
                                                                                                                                        <div className="input-field col s12">
                                                                                                                                            <input
                                                                                                                                                type="text"
                                                                                                                                                id={fieldRepeteurItem.name_input + '-' + fieldRepeteurItem.id + '-' + idx + '-' + Ridx + '-' + FRIidx}
                                                                                                                                                name={fieldRepeteurItem.name_input}
                                                                                                                                                value={fieldRepeteurItem.value || ''}
                                                                                                                                                data-fieldname={field.name_input}
                                                                                                                                                onChange={e => handleRepeteurChangeInput(e, field.id, Ridx, fieldRepeteurItem.id)} />
                                                                                                                                            <label htmlFor={fieldRepeteurItem.name_input + '-' + fieldRepeteurItem.id + + '-' + Ridx + '-' + FRIidx} className={fieldRepeteurItem.value ? 'active' : ''}>{fieldRepeteurItem.titre_input}</label>
                                                                                                                                        </div>
                                                                                                                                    </div>

                                                                                                                                    : fieldRepeteurItem.type_input === 'Textarea' ?
                                                                                                                                        <div className="row">
                                                                                                                                            <div className="input-field col s12">
                                                                                                                                                <textarea
                                                                                                                                                    id={fieldRepeteurItem.name_input + '-' + fieldRepeteurItem.id + '-' + Ridx + '-' + FRIidx}
                                                                                                                                                    name={fieldRepeteurItem.name_input}
                                                                                                                                                    className="materialize-textarea"
                                                                                                                                                    value={fieldRepeteurItem.value || ''}
                                                                                                                                                    data-fieldname={field.name_input}
                                                                                                                                                    onChange={e => handleRepeteurChangeInput(e, field.id, Ridx, fieldRepeteurItem.id)}></textarea>
                                                                                                                                                <label htmlFor={fieldRepeteurItem.name_input + '-' + fieldRepeteurItem.id + '-' + Ridx + '-' + FRIidx} className={fieldRepeteurItem.value ? 'active' : ''}>{fieldRepeteurItem.titre_input}</label>
                                                                                                                                            </div>
                                                                                                                                        </div>

                                                                                                                                        : fieldRepeteurItem.type_input === 'Select' ?
                                                                                                                                            <div className="row">
                                                                                                                                                <div className="input-field col s12">
                                                                                                                                                    <label className="active" htmlFor={fieldRepeteurItem.name_input + '-' + fieldRepeteurItem.id + '-' + Ridx + '-' + FRIidx} >{fieldRepeteurItem.titre_input}</label>
                                                                                                                                                    <select
                                                                                                                                                        id={fieldRepeteurItem.name_input + '-' + fieldRepeteurItem.id + '-' + Ridx + '-' + FRIidx}
                                                                                                                                                        name={fieldRepeteurItem.name_input}
                                                                                                                                                        defaultValue={fieldRepeteurItem.value || ''}
                                                                                                                                                        data-fieldname={field.name_input}
                                                                                                                                                        onChange={e => handleRepeteurChangeInput(e, field.id, Ridx, fieldRepeteurItem.id)} >
                                                                                                                                                        {Array.isArray(fieldRepeteurItem.select_option) ? fieldRepeteurItem.select_option.map((value, optionidx) => {
                                                                                                                                                            return (<option key={optionidx} value={value}>{value}</option>)
                                                                                                                                                        }) : ''}
                                                                                                                                                    </select>
                                                                                                                                                </div>
                                                                                                                                            </div>

                                                                                                                                            : fieldRepeteurItem.type_input === 'CheckBox' ?
                                                                                                                                                <div className="row">
                                                                                                                                                    <div className="col s12">
                                                                                                                                                        <p className="mb-1">
                                                                                                                                                            <label className="active" htmlFor={fieldRepeteurItem.name_input + '-' + fieldRepeteurItem.id + '-' + Ridx + '-' + FRIidx} >{fieldRepeteurItem.titre_input}</label>
                                                                                                                                                        </p>
                                                                                                                                                        {Array.isArray(fieldRepeteurItem.options) ? fieldRepeteurItem.options.map((option, optionidx) => {
                                                                                                                                                            return (
                                                                                                                                                                <p className="mb-1" key={optionidx}>
                                                                                                                                                                    <label>
                                                                                                                                                                        <input
                                                                                                                                                                            type='checkbox'
                                                                                                                                                                            name={fieldRepeteurItem.name_input}
                                                                                                                                                                            value={option.value}
                                                                                                                                                                            checked={option.state}
                                                                                                                                                                            data-fieldname={field.name_input}
                                                                                                                                                                            onChange={e => handleRepeteurChangeCheckbox(e, field.id, optionidx, Ridx, fieldRepeteurItem.id)} />
                                                                                                                                                                        <span>{option.value}</span>
                                                                                                                                                                    </label>
                                                                                                                                                                </p>
                                                                                                                                                            )
                                                                                                                                                        }) : ''}
                                                                                                                                                    </div>
                                                                                                                                                </div>

                                                                                                                                                : fieldRepeteurItem.type_input === 'Radio' ?
                                                                                                                                                    <div className="row">
                                                                                                                                                        <div className="col s12">
                                                                                                                                                            <p className="mb-1">
                                                                                                                                                                <label className="active" htmlFor={fieldRepeteurItem.name_input + '-' + fieldRepeteurItem.id + '-' + Ridx + '-' + FRIidx} >{fieldRepeteurItem.titre_input}</label>
                                                                                                                                                            </p>
                                                                                                                                                            {Array.isArray(fieldRepeteurItem.options) ? fieldRepeteurItem.options.map((option, optionidx) => {
                                                                                                                                                                return (
                                                                                                                                                                    <p className="mb-1" key={optionidx}>
                                                                                                                                                                        <label>
                                                                                                                                                                            <input
                                                                                                                                                                                type='radio'
                                                                                                                                                                                name={fieldRepeteurItem.name_input + '_' + Ridx + '_' + fieldRepeteurItem.id}
                                                                                                                                                                                value={option.value}
                                                                                                                                                                                checked={option.state}
                                                                                                                                                                                data-fieldname={field.name_input}
                                                                                                                                                                                onChange={e => handleRepeteurChangeRadio(e, field.id, optionidx, Ridx, fieldRepeteurItem.id)} />
                                                                                                                                                                            <span>{option.value}</span>
                                                                                                                                                                        </label>
                                                                                                                                                                    </p>
                                                                                                                                                                )
                                                                                                                                                            }) : ''}
                                                                                                                                                        </div>
                                                                                                                                                    </div>

                                                                                                                                                    : fieldRepeteurItem.type_input === 'Image' ?
                                                                                                                                                        <div className="row">
                                                                                                                                                            <div className="input-field col s12">
                                                                                                                                                                <label className="active" htmlFor={fieldRepeteurItem.name_input + '-' + fieldRepeteurItem.id + '-' + Ridx + '-' + FRIidx} >{fieldRepeteurItem.titre_input}</label>
                                                                                                                                                            </div>

                                                                                                                                                            <div className="input-field col s12">
                                                                                                                                                                <input
                                                                                                                                                                    type="hidden"
                                                                                                                                                                    name={fieldRepeteurItem.name_input}
                                                                                                                                                                    value={fieldRepeteurItem.value || ''}
                                                                                                                                                                    data-fieldname={field.name_input}
                                                                                                                                                                    onChange={e => handleRepeteurChangeInput(e, field.id, Ridx, fieldRepeteurItem.id)} />
                                                                                                                                                                <button
                                                                                                                                                                    type="button"
                                                                                                                                                                    className="btn posts-form-btn"
                                                                                                                                                                    onClick={e => openFilesManagerModalImg(field.id, Ridx, fieldRepeteurItem.id)} width="200" >image</button>
                                                                                                                                                                <br />
                                                                                                                                                                {fieldRepeteurItem.value ? (
                                                                                                                                                                    <img src={assetsURL + fieldRepeteurItem.value} alt="users avatar" className="z-depth-4 users-form-avatar posts-form-img" height="150" width="200"></img>
                                                                                                                                                                ) : null}
                                                                                                                                                            </div>
                                                                                                                                                        </div>

                                                                                                                                                        : fieldRepeteurItem.type_input === 'Wysiwyg' ?
                                                                                                                                                            <div className="row">
                                                                                                                                                                <div className="input-field col s12">
                                                                                                                                                                    <label className="active" htmlFor={fieldRepeteurItem.name_input + '-' + fieldRepeteurItem.id + '-' + Ridx + '-' + FRIidx} >{fieldRepeteurItem.titre_input}</label>
                                                                                                                                                                </div>
                                                                                                                                                                <div className="input-field col s12">
                                                                                                                                                                    <input
                                                                                                                                                                        type="hidden"
                                                                                                                                                                        id={fieldRepeteurItem.name_input + '-' + fieldRepeteurItem.id + '-' + Ridx + '-' + FRIidx}
                                                                                                                                                                        name={fieldRepeteurItem.name_input}
                                                                                                                                                                        value={fieldRepeteurItem.value || ''}
                                                                                                                                                                        data-fieldname={field.name_input}
                                                                                                                                                                        onChange={e => handleRepeteurChangeInput(e, field.id, Ridx, fieldRepeteurItem.id)} />

                                                                                                                                                                    <CKEditor
                                                                                                                                                                        editor={ClassicEditor}
                                                                                                                                                                        data={fieldRepeteurItem.value || ''}
                                                                                                                                                                        onChange={(event, editor) => handleRepeteurChangeWysiwyg(event, field.id, editor, Ridx, fieldRepeteurItem.id)}
                                                                                                                                                                    />
                                                                                                                                                                </div>
                                                                                                                                                            </div>

                                                                                                                                                            : fieldRepeteurItem.type_input === 'ImageGallery' ?
                                                                                                                                                                <div className="post_repeteur">
                                                                                                                                                                    <div className="card card-content" style={{ background: 'rgb(30 30 30)' }}>
                                                                                                                                                                        <div className="post_repeteur_titre"> {fieldRepeteurItem.titre_input} </div>

                                                                                                                                                                        <div className="row image-gallery-container" >
                                                                                                                                                                            {fieldRepeteurItem.value ?

                                                                                                                                                                                <ReactSortable
                                                                                                                                                                                    handle='.gallery-image-item'
                                                                                                                                                                                    list={fieldRepeteurItem.value} setList={(data) => {

                                                                                                                                                                                        let index = postTemplatesFields.findIndex((itmInner) => itmInner.id === field.id);

                                                                                                                                                                                        let fieldItems = [...postTemplatesFields];
                                                                                                                                                                                        let item_index = fieldItems[index].fields[Ridx].findIndex((itmInner) => itmInner.id === fieldRepeteurItem.id);

                                                                                                                                                                                        fieldItems[index].fields[Ridx][item_index].value = data;
                                                                                                                                                                                        setPostTemplatesFields(fieldItems);

                                                                                                                                                                                    }}
                                                                                                                                                                                >

                                                                                                                                                                                    {fieldRepeteurItem.value.map((imgItem, idx) => {
                                                                                                                                                                                        return (
                                                                                                                                                                                            <div key={'repeteur-gallery-image-item' + field.id + '-' + Ridx + '-' + fieldRepeteurItem.id + '-' + idx} className="gallery-image-item" style={{ display: 'inline-block', position: 'relative' }}>
                                                                                                                                                                                                <button className="btn waves-effect waves-light left gallery-image-item-delete" type="button" name="action" onClick={(e) => deleteGalleryItem(imgItem, field.id, Ridx, fieldRepeteurItem.id)}><i className="material-icons left">delete</i></button>
                                                                                                                                                                                                <img src={assetsURL + imgItem.path} alt="users avatar" className="z-depth-4 users-form-avatar posts-form-img" height="150" width="200" style={{ margin: '10px' }}></img>
                                                                                                                                                                                            </div>
                                                                                                                                                                                        )
                                                                                                                                                                                    })
                                                                                                                                                                                    }
                                                                                                                                                                                </ReactSortable>
                                                                                                                                                                                : null}
                                                                                                                                                                        </div>

                                                                                                                                                                        <div className="row">
                                                                                                                                                                            <button type="button" className="btn cyan waves-effect waves-light right" onClick={e => openFilesManagerModalGallery(fieldRepeteurItem.value, field.id, Ridx, fieldRepeteurItem.id)} name="action">
                                                                                                                                                                                {_trans('Add an image', locosBO)}
                                                                                                                                                                                <i className="material-icons left">add</i>
                                                                                                                                                                            </button>
                                                                                                                                                                        </div>
                                                                                                                                                                    </div>
                                                                                                                                                                </div>

                                                                                                                                                                : null
                                                                                                                            }

                                                                                                                        </div>
                                                                                                                    )
                                                                                                                })}

                                                                                                            </div>
                                                                                                        )
                                                                                                    })}

                                                                                                </ReactSortable>
                                                                                                : (<img className="loading-icon" src="/loading.svg" alt="loading" />)}

                                                                                            <div className="row">
                                                                                                <button type="button" className="btn cyan waves-effect waves-light right" onClick={e => addRepeteurItem(e, idx)} name="action">
                                                                                                    {_trans('Add a field', locosBO)}
                                                                                                    <i className="material-icons left">add</i>
                                                                                                </button>
                                                                                            </div>

                                                                                        </div>
                                                                                    </div>

                                                                                    : ""

                                            )
                                        }
                                        )}
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="content-overlay"></div>
            </div>
        </div>

        {/* ) : ( <img className="loading-icon" src="/loading.svg" alt="loading" /> ) } */}

    </React.Fragment>;

}