import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form, Field, Formik, FieldArray} from 'formik'

import * as Yup from 'yup';
import Errors from '../../validations/Errors';
import { toast } from 'react-toastify';
import {listLeads, saveLead, deleteLead, selectedLead, toggleLeadsSidebar} from '../../actions/leadsActions';
import { userAuthPermissions } from '../../actions/usersActions';

import { _trans } from '../../actions/locoBOActions';

export default function FormComponent() {

    const { locosBO, loading: locosBOLoading, error: locosBOError} = useSelector(state => state.listLocosBO) 

    const CreateLeadsSchema = Yup.object().shape({
        name: Yup.string().min(3, Errors.min({ name: _trans('Name',locosBO), number: "3" })).required(Errors.required({ name: _trans('Name',locosBO)}))
    })

    const dispatch = useDispatch();

    const [id, setId] = useState('');

    const [selectAll , setSelectAll] = useState(false);

    const [allCheckboxes , setCheckboxes] = useState([]);

    const [name, setName] = useState('');
    const [tel, setTel] = useState('');
    const [email, setEmail] = useState('');
    const [company, setCompany] = useState('');
    const [source, setSource] = useState('');
    const [info, setInfo] = useState('');
    const [adresse, setAdresse] = useState('');
    const [commentaires, setCommentaires] = useState('');
    const [statuts, setStatuts] = useState('');
    

    const [saveSuccess, setSaveSuccess] = useState(false);

    const leadsToggleSidebar = useSelector(state => state.toggleLeadsSidebar);

    const leadSelected = useSelector(state => state.selectedLead);

    const leadsSave = useSelector(state => state.leadsSave);

    const [value, setValue] = useState(0); // integer state
    const { loading : loadingSave, 
            success: successSave, 
            error: errorSave} = leadsSave;

     useEffect(() => {
        if(leadSelected.lead){
            setId(leadSelected.lead.id);
            setName(leadSelected.lead.name);
            setTel(leadSelected.lead.tel);
            setEmail(leadSelected.lead.email);
            setCompany(leadSelected.lead.company);
            setSource(leadSelected.lead.source);
            setInfo(leadSelected.lead.info);
            setAdresse(leadSelected.lead.adresse);
            setCommentaires(leadSelected.lead.commentaires);
            setStatuts(leadSelected.lead.statuts);
        }else{
            setId('');
            setName('');
            setTel('');
            setEmail('');
            setCompany('');
            setSource('');
            setInfo('');
            setAdresse('');
            setCommentaires('');
            setStatuts('');
        }
            
            if(saveSuccess){
                setSaveSuccess(false);
                dispatch(listLeads());
                toast.success(_trans('Lead Saved',locosBO));
            }
    
    }, [leadSelected, saveSuccess]);
 
    const closeFormSidebar = () => {
        dispatch( selectedLead(null) );
        dispatch( toggleLeadsSidebar(false) );
    }
    
     const  handleSubmit = (values) =>{
         dispatch(saveLead(values ,(response)=>{
             dispatch(userAuthPermissions());
            setSaveSuccess(true);
        }));
     }

    const selectAllHandler = () => {
        let checkboxes = allCheckboxes;
        for (let i = 0; i < checkboxes.length; i++) {
            checkboxes[i] = !selectAll;
        }
        setCheckboxes(checkboxes);
        setSelectAll(!selectAll);
    }


    return <React.Fragment>
    {loadingSave && <div></div> }
    {errorSave && <div>{errorSave}</div> }
        <div className={leadsToggleSidebar.toggle ? "contact-compose-sidebar leads-sidebar show" : "contact-compose-sidebar leads-sidebar"}>
        <div className="card quill-wrapper">
        <div className="card-content pt-0">
        <div className="card-header display-flex pb-2">
            <h3 className="card-title lead-title-label">{ id === '' ? _trans('Create New Lead',locosBO) : _trans('Infos Lead',locosBO)}</h3>
            <div className="close close-icon" onClick={closeFormSidebar}>
                <i className="material-icons">close</i>
            </div>
        </div>
        <div className="divider"></div> 

        <Formik
            initialValues={{
                id: id ? id : '',
                name: name ? name : '',
                tel: tel ? tel : '',
                email: email ? email : '',
                company: company ? company : '',
                info: info ? info : '',
                source: source ? source :'direct',
                adresse: adresse ? adresse : '',
                commentaires: commentaires ? commentaires : '',
                statuts: statuts ? statuts : '',
            }}
            validationSchema={CreateLeadsSchema}
            enableReinitialize={true}
            onSubmit={async (values, {resetForm}) => {
                closeFormSidebar();
                values = { ...values};
                setSelectAll(false);
                handleSubmit(values);
                resetForm({values: ''});
            }}
        >
        {({ values, errors, touched, isSubmitting ,setFieldValue}) => (
                <Form className="edit-lead-item mb-5 mt-5">
                    
                    <div className="lead-sidebar-form">

                        <div className="lead-commercial-col">
                            <div class="lead-commercial-header">Notes commerciaux</div>

                            <div className="input-field col s12">
                                <Field id="leads_adresse" name="adresse" type="text" className={errors.adresse ? "error validate" : "validate"} />
                                <label htmlFor="leads_adresse" className="active">{_trans('Adresse',locosBO)}</label>
                                {errors.adresse && touched.adresse ? (
                                    <small className="errorTxt2"><div id="cemail-error" className="error">{errors.adresse}</div></small>
                                ) : null}
                            </div>
                            <div className="input-field col s12">
                                <Field id="leads_commentaires" name="commentaires" component="textarea" className={errors.commentaires ? "error validate" : "validate"} />
                                <label htmlFor="leads_commentaires" className="active">{_trans('Commentaires',locosBO)}</label>
                            </div>
                            <div className="input-field col s12">
                                <Field id="leads_statuts" name="statuts" type="text" className={errors.statuts ? "error validate" : "validate"} />
                                <label htmlFor="leads_statuts" className="active">{_trans('Statuts',locosBO)}</label>
                                {errors.statuts && touched.statuts ? (
                                    <small className="errorTxt2"><div id="cemail-error" className="error">{errors.statuts}</div></small>
                                ) : null}
                            </div>
                            
                        </div>

                        <div className="lead-info-col">
                            <div className="input-field col s12">
                                <Field id="leads_name" name="name" type="text" className={errors.name ? "error validate" : "validate"} />
                                <label htmlFor="leads_name" className="active">{_trans('Name',locosBO)}</label>
                                {errors.name && touched.name ? (
                                    <small className="errorTxt2"><div id="cemail-error" className="error">{errors.name}</div></small>
                                ) : null}
                            </div>
                            <div className="input-field col s12">
                                <Field id="leads_tel" name="tel" type="text" className={errors.tel ? "error validate" : "validate"} />
                                <label htmlFor="leads_tel" className="active">{_trans('Telephone',locosBO)}</label>
                                {errors.tel && touched.tel ? (
                                    <small className="errorTxt2"><div id="cemail-error" className="error">{errors.tel}</div></small>
                                ) : null}
                            </div>
                            <div className="input-field col s12">
                                <Field id="leads_email" name="email" type="text" className={errors.email ? "error validate" : "validate"} />
                                <label htmlFor="leads_email" className="active">{_trans('Email',locosBO)}</label>
                                {errors.email && touched.email ? (
                                    <small className="errorTxt2"><div id="cemail-error" className="error">{errors.email}</div></small>
                                ) : null}
                            </div>
                            <div className="input-field col s12">
                                <Field id="leads_source" name="source" type="text" className={errors.source ? "error validate" : "validate"} />
                                <label htmlFor="source" className="active">{_trans('Source',locosBO)}</label>
                                {errors.source && touched.source ? (
                                    <small className="errorTxt2"><div id="cemail-error" className="error">{errors.source}</div></small>
                                ) : null}
                            </div>
                            <div className="input-field col s12">
                                <Field id="leads_info" name="info" component="textarea" className={errors.info ? "error validate" : "validate"} />
                                <label htmlFor="info" className="active">{_trans('Infos complémentaires',locosBO)}</label>
                            </div>
                        </div>
                    </div>
                    

                    <div className="card-action pl-0 pr-0 right-align">
                        <button type="submit" className="btn-small waves-effect waves-light add-lead" disabled={isSubmitting}>
                            {!id? (
                                <span>{_trans('Save',locosBO)}</span>
                                ) : (
                                <span>{_trans('Update',locosBO)}</span>
                            )}
                        </button>
                    </div>

                </Form>
            )}

        </Formik>

        </div>
        </div>
        </div>
    </React.Fragment> 
}