import React, { useEffect, useMemo, useState } from 'react';
import DataTable from 'react-data-table-component';
import { useSelector, useDispatch } from 'react-redux';
import {listLeads, saveLead, deleteLead, selectedLead, toggleLeadsSidebar} from '../../actions/leadsActions';
import { saveContact } from '../../actions/contactsActions';
import Swal from 'sweetalert2';
import useHasPermission from '../../hooks/useHasPermission'

import { _trans } from '../../actions/locoBOActions';

import {CSVLink} from 'react-csv';

import moment from 'moment';

export default function LeadsDatatable() {

    const { locosBO, loading: locosBOLoading, error: locosBOError} = useSelector(state => state.listLocosBO) 

    const dispatch = useDispatch();

    const [deleteSuccess, setDeleteSuccess] = useState(false);

    const [filtredData, setFiltredData] = useState([]);

    const leadsList = useSelector(state => state.leadsList);
    const { leads, 
            loading,
            error } = leadsList;
    const canUpdate = useHasPermission("/leads", "update");
    const canDelete = useHasPermission("/leads", "delete");
    const leadDelete = useSelector(state => state.leadsDelete);
    const { loading: loadingDelete,
            success: successDelete,
            error: errorDelete } = leadDelete;

    const leadsSave = useSelector(state => state.leadsSave);
    const { loading : loadingSave, 
            success: successSave, 
            error: errorSave} = leadsSave;

    const columns = [
        {
            name: _trans('Name',locosBO),
            selector: 'name',
            sortable: true,
        },
        {
            name: _trans('Telephone',locosBO),
            selector: 'tel',
            sortable: false,
        },
        {
            name: _trans('Email',locosBO),
            selector: 'email',
            sortable: false,
        },
        {
            name: _trans('Source',locosBO),
            selector: 'source',
            sortable: false,
        },
        {
            name: _trans('Date',locosBO),
            selector: 'created_at',
            sortable: false,
            format: (row) => moment(row.created_at).format('DD/mm/YYYY HH:mm'),
        },
        {
            cell: (lead) => <a className="btn-flat p-0" style={{width: "100%", height: "100%"}} href={"mailto:"+lead.email} ><i className="material-icons">email</i></a>,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
            width: '50px'
        },
        {
            cell: (lead) => <button className="btn-flat p-0" style={{width: "100%", height: "100%"}} onClick={ (e) => { addContactHandler(lead); } }>
                                <i className="material-icons">person_add</i>
                            </button>,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
            width: '50px'
        },
        {
            cell: (lead) => canUpdate? <button className="btn-flat p-0" style={{width: "100%", height: "100%"}} onClick={ (e) => { editLeadsHandler(lead); } }>
                                <i className="material-icons">edit</i>
                            </button>:undefined,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
            width: '50px'
        },
        {
            cell: (lead) => canDelete ?<button className="btn-flat p-0" style={{width: "100%", height: "100%"}} onClick={ (e) => { deleteLeadsHandler(lead); } }>
                                <i className="material-icons">delete</i>
                            </button>:undefined,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
            width: '50px'
        }
    ];
    
    useEffect(() => {

        setFiltredData(leads);

        if(deleteSuccess){
            setDeleteSuccess(false);
            dispatch(listLeads());
        }

    }, [successSave, deleteSuccess, leads]);

    const addContactHandler = ( leadData ) => {
        
        delete leadData.id;

        console.log(leadData);

        dispatch(saveContact(leadData ,(response)=>{
            //setSaveSuccess(true);
            console.log("contact saved");
            Swal.fire(
                _trans('Contact',locosBO),
                _trans('Contact added successfully',locosBO),
                'success'
              )
       }));
    }

    const editLeadsHandler = ( leadData ) => {
        dispatch(selectedLead(leadData));
        dispatch(toggleLeadsSidebar(true));
    }

    const deleteLeadsHandler = ( leadData ) => {
        Swal.fire({
            title: _trans('Are you sure?',locosBO),
            icon: 'warning',
            showCancelButton: true,
            cancelButtonColor: '#3085d6',
            confirmButtonColor: '#d33',
            cancelButtonText: _trans('Cancel',locosBO),
            confirmButtonText: _trans('Delete',locosBO)
          }).then((result) => {
            if (result.isConfirmed) {

                dispatch(deleteLead(leadData,(response)=>{
                    setDeleteSuccess(true);
                    Swal.fire(
                        _trans('Delete',locosBO),
                        _trans('Lead successfully deleted',locosBO),
                        'success'
                      )
                }));
              
            }
          })
    }

    const dataFiltre = (e) =>{
        let textFiltre = e.target.value;
        let data = leads.filter(item => item.name && item.name.toLowerCase().includes(textFiltre.toLowerCase()));
        setFiltredData(data);
    }

    let headers = [
        { label: "Date", key: "created_at" },
        { label: "Name", key: "name" },
        { label: "Telephone", key: "tel" },
        { label: "Email", key: "email" },
        { label: "Company", key: "company" },
        { label: "Subject", key: "subject" },
        { label: "Info", key: "info" },
        { label: "Source", key: "source" },
    ];
    
    return <React.Fragment>
        {loading || loadingDelete && <div></div>} 
        {error && <div>{error}</div>} 
        {errorDelete && <div>{errorDelete}</div>}   

        {leads ? (
            <CSVLink 
            headers={headers}
            data={leads} 
            separator={";"} 
            filename={"Leads.csv"}
            className="btn-export"
            > Export </CSVLink>
        ) : ""}

            <div className="content-area content-right" style={{ width: "calc(100% - 140px)" }}>
                <div className="app-wrapper">
                    <div className="datatable-search">
                        <i className="material-icons mr-2 search-icon">search</i>
                        <input type="text" placeholder="Search" className="app-filter" id="global_filter" onChange={dataFiltre} />
                    </div>
                    <div id="button-trigger" className="card card card-default scrollspy border-radius-6 fixed-width">
                    <div className="p-0 users-table">
                        <DataTable
                            columns={columns}
                            data={filtredData}
                            selectableRows={true}
                            selectableRowsHighlight={true}
                            pagination={true}
                            paginationPerPage={30}
                            paginationComponentOptions={{noRowsPerPage: true}}
                            highlightOnHover={true}
                            noHeader={true}
                            striped={true}
                            theme="solarized"
                        />
                    </div>
                    </div>
                </div>
            </div>      
        </React.Fragment>;
}

